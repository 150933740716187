body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.brText{
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 800;
  text-shadow: 2px 2px rgb(70, 70, 70, 0.6);
  padding: 7px;
}
#aboutWrapper {
  background-image: url(/static/media/akOpt.aaa7e1b4.jpg);
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#aboutWrapperAnimation {
  background-color: rgba(0, 0, 0, 0.5);
}

#profilePic {
  width: 20vw;
  height: 20vw;
  box-shadow: 5px 7px 2px 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin: 0 auto;
  margin-left: 37.5vw;
  margin-top: 150px;
  max-width: 500px;
  max-height: 500px;
}

#contactPhone,
#contactEmail,
#contactLinkedin,
#contactGithub {
  margin: 5px;
  cursor: pointer;
  float: left;
  width: 5%;
  margin-left: 15vw;
  padding-top: 75px;
  -webkit-filter: invert(90%);
          filter: invert(90%);
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

#contactPhone:before,
#contactEmail:before,
#contactLinkedin:before,
#contactGithub:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

#contactPhone:hover, #contactPhone:focus, #contactPhone:active,
#contactEmail:hover,
#contactEmail:focus,
#contactEmail:active,
#contactLinkedin:hover,
#contactLinkedin:focus,
#contactLinkedin:active,
#contactGithub:hover,
#contactGithub:focus,
#contactGithub:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}

#contactPhone:hover:before, #contactPhone:focus:before, #contactPhone:active:before,
#contactEmail:hover:before,
#contactEmail:focus:before,
#contactEmail:active:before,
#contactLinkedin:hover:before,
#contactLinkedin:focus:before,
#contactLinkedin:active:before,
#contactGithub:hover:before,
#contactGithub:focus:before,
#contactGithub:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

.linkHover {
  width: 100%;
  height: 100%;
}

.aboutText {
  display: inline-block;
  margin: 30px;
  text-align: center;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
  font-size: calc(0.8rem + 1vw);
  padding-top: 25px;
  padding-bottom: 50px;
}

@media screen and (max-width: 700px) {
  #profilePic {
    width: 30vw;
    height: 30vw;
  }
  #aboutWrapper {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .aboutText {
    font-size: calc(0.8rem + .7vw);
  }
  #profilePic {
    width: 15vw;
    height: 15vw;
    margin-left: 42vw;
  }
}
/*# sourceMappingURL=about.css.map */
/* Sweep To Right */
.navbar {
  width: 100%;
  box-shadow: 0px 5px 5px;
  position: fixed;
  height: 55px;
  z-index: 100;
}

.buttonClass {
  display: flex;
  top: 0;
  background: none;
  border: none;
  width: 65px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: #bebebe;
}

.buttonClass:before {
  content: "";
  position: absolute;
  height: 7px;
  z-index: -1;
  top: 25px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #138496;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.buttonClass:hover, .buttonClass:focus, .buttonClass:active {
  color: white;
}

.buttonClass:hover:before, .buttonClass:focus:before, .buttonClass:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.navName {
  color: white;
  cursor: pointer;
}

.navNameMoble {
  color: white;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 700px) {
  .navbar {
    width: 100%;
  }
  .navName {
    display: none;
  }
  .navNameMoble {
    display: inline;
    display: initial;
  }
}
/*# sourceMappingURL=navbar.css.map */
.Wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 10vw 36vw 35vw 10vw;
      grid-template-columns: 10vw 36vw 35vw 10vw;
  -ms-grid-rows: auto 15vw 15vw auto;
      grid-template-rows: auto 15vw 15vw auto;
  grid-gap: 2vw;
  background: url(/static/media/orOpt.b799928f.jpg);
  background-size: cover;
  background-position: center;
  padding-bottom: 50px;
}

.Wrapper .plantifyOverlay {
  margin-top: 20px;
  margin-bottom: 4vw;
  margin-left: 20px;
  position: relative;
  z-index: 1;
  height: 10vw;
  width: 32vw;
  display: flex;
}

.Wrapper .plantifyOverlay:hover span {
  visibility: visible;
  visibility: initial;
  display: inline;
  display: initial;
}

.Wrapper .plantifyOverlay:hover .techImg {
  visibility: visible;
  visibility: initial;
  display: inline;
  display: initial;
  opacity: 1;
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
}

.Wrapper .projectImg {
  position: relative;
  z-index: 1 !important;
  height: 15vw;
  width: 32vw;
  visibility: visible;
  top: -5vw;
}

.Wrapper .projectInfo {
  margin-top: 5vw;
  font-family: 'Lexend Deca', sans-serif;
  font-size: calc(0.4rem + .5vw);
  text-align: center;
}

.Wrapper span {
  position: absolute;
  color: white;
  height: calc(15vw + 1px);
  width: calc(32vw + 1px);
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 4;
  visibility: hidden;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.Wrapper span:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.Wrapper span:hover, .Wrapper span:focus, .Wrapper span:active {
  color: white;
}

.Wrapper span:hover:before, .Wrapper span:focus:before, .Wrapper span:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.Wrapper .techImg {
  position: relative;
  color: white;
  width: 5vw;
  left: 0;
  pointer-events: none;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
}

.plantifyLeft {
  margin-left: 4%;
}

.jamSeshLeft {
  margin-left: 12%;
}

.rpgLeft {
  margin-left: 18%;
}

.trainLeft {
  margin-left: 10%;
}

.memoryLeft {
  margin-left: 25%;
}

@media screen and (max-width: 700px) {
  .Wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto;
        grid-template-columns: auto;
    -ms-grid-rows: auto 40vw 40vw auto auto 40vw 40vw auto auto 40vw 40vw;
        grid-template-rows: auto 40vw 40vw auto auto 40vw 40vw auto auto 40vw 40vw;
    align-content: center;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .plantifyOverlay {
    width: 100vw !important;
  }
  .projectContainer {
    margin-left: 12vw;
  }
  .projectImg {
    margin-top: -5vw !important;
    width: 70vw !important;
    height: 34vw !important;
  }
  .bottomImg {
    margin-top: 10vw !important;
    margin-left: -10vw !important;
  }
  span {
    margin-top: 5vw !important;
    margin-left: 12vw;
    width: calc(70vw + 1px) !important;
    height: calc(34vw + 3px) !important;
    visibility: visible !important;
    visibility: initial !important;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .techImg {
    margin-top: 5vw !important;
    width: 10vw !important;
    position: absolute;
    visibility: visible !important;
    visibility: initial !important;
    opacity: 1 !important;
  }
  .spaceHolder {
    opacity: 0 !important;
  }
  .projectInfo {
    margin-top: 10vw !important;
    font-size: calc(0.5rem + .7vw) !important;
  }
}
/*# sourceMappingURL=projects.css.map */
.skillsBackground {
  background: url(/static/media/borah.6f16f63e.jpg);
  background-size: cover;
  width: 120vw;
  background-position: center;
  min-height: calc(20vw + 300px);
  margin-left: -10vw;
  position: absolute;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

#scene {
  width: 100%;
  overflow: hidden;
}

.sceneClass {
  height: calc(20vw + 300px);
}

.backgroundOverlay {
  position: relative;
  min-height: calc(20vw + 300px);
  top: calc(-20vw + -300px);
  margin-bottom: calc(-20vw + -300px);
  overflow: hidden;
}

.skillsImg {
  width: 50%;
  -webkit-transition: transform 1.5s ease-in-out;
  -webkit-transition: -webkit-transform 1.5s ease-in-out;
  transition: -webkit-transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out, -webkit-transform 1.5s ease-in-out;
}

.mongoImg {
  width: 60%;
}

.firebaseImg {
  margin-left: 23%;
  width: 37%;
}

.skillsWrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 33% 67%;
      grid-template-rows: 33% 67%;
  -ms-grid-columns: 33% 33% 33%;
      grid-template-columns: 33% 33% 33%;
  padding-bottom: 50px;
  min-height: calc(20vw + 300px);
}

.databaseWrapper {
  text-align: center;
  width: 33vw;
  -webkit-transition: transform 1.5s ease-in-out;
  -webkit-transition: -webkit-transform 1.5s ease-in-out;
  transition: -webkit-transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out, -webkit-transform 1.5s ease-in-out;
  height: auto;
  color: #F4F4F4;
}

.databaseWrapper:hover {
  -webkit-transform: scale(2) translate(16.5vw, 25%);
          transform: scale(2) translate(16.5vw, 25%);
  left: 0;
  position: absolute;
  z-index: 20;
  height: auto;
}

.databaseWrapper:hover .firebaseImg {
  -webkit-transform: translate(0%, -100%);
          transform: translate(0%, -100%);
}

.database {
  justify-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto auto;
      grid-template-columns: auto auto auto;
  -ms-grid-rows: 50% 50%;
      grid-template-rows: 50% 50%;
}

.frontEndWrapper {
  color: #F4F4F4;
  text-align: center;
  left: 33vw;
  width: 33vw;
  -webkit-transition: transform 1.5s ease-in-out;
  -webkit-transition: -webkit-transform 1.5s ease-in-out;
  transition: -webkit-transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out, -webkit-transform 1.5s ease-in-out;
}

.frontEndWrapper:hover {
  -webkit-transform: scale(2) translate(0%, 25%);
          transform: scale(2) translate(0%, 25%);
  position: absolute;
  z-index: 20;
}

.frontEndWrapper:hover .cssImg {
  -webkit-transform: translate(0%, -220%);
          transform: translate(0%, -220%);
}

.frontEndWrapper:hover .cssGridImg {
  -webkit-transform: translate(0%, -163%);
          transform: translate(0%, -163%);
}

.frontEndWrapper:hover .htmlImg {
  -webkit-transform: translate(230%, -150%);
          transform: translate(230%, -150%);
}

.frontEndWrapper:hover .jsImg {
  -webkit-transform: translate(0%, -150%);
          transform: translate(0%, -150%);
}

.frontEndWrapper:hover .jQueryImg {
  -webkit-transform: translate(-225%, -150%);
          transform: translate(-225%, -150%);
}

.frontEndWrapper:hover .muImg {
  -webkit-transform: translate(0%, -150%);
          transform: translate(0%, -150%);
}

.frontEndWrapper:hover .reactImg {
  -webkit-transform: translate(0%, -300%);
          transform: translate(0%, -300%);
}

.frontEndWrapper:hover .sassImg {
  -webkit-transform: translate(10%, -150%);
          transform: translate(10%, -150%);
}

.frontEnd {
  justify-items: center;
  display: -ms-grid;
  display: grid;
  grid-gap: 1vw;
  -ms-grid-columns: 18% 18% 18% 18% 18%;
      grid-template-columns: 18% 18% 18% 18% 18%;
  -ms-grid-rows: 20% 20% 20% 20% 20%;
      grid-template-rows: 20% 20% 20% 20% 20%;
}

.backEndWrapper {
  color: #F4F4F4;
  text-align: center;
  width: 33vw;
  left: 66vw;
  -webkit-transition: transform 1.5s ease-in-out;
  -webkit-transition: -webkit-transform 1.5s ease-in-out;
  transition: -webkit-transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out, -webkit-transform 1.5s ease-in-out;
}

.backEndWrapper:hover {
  -webkit-transform: scale(2) translate(-16.5vw, 25%);
          transform: scale(2) translate(-16.5vw, 25%);
  position: absolute;
  z-index: 20;
}

.backEndWrapper:hover .apiImg {
  -webkit-transform: translate(-100%, -100%);
          transform: translate(-100%, -100%);
}

.backEndWrapper:hover .ajaxImg {
  -webkit-transform: translate(-100%, 0%);
          transform: translate(-100%, 0%);
}

.backEndWrapper:hover .nodeImg {
  -webkit-transform: translate(100%, -200%);
          transform: translate(100%, -200%);
}

.backEndWrapper:hover .jsonImg {
  -webkit-transform: translate(100%, -100%);
          transform: translate(100%, -100%);
}

.backEnd {
  justify-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto auto;
      grid-template-columns: auto auto auto;
  -ms-grid-rows: 33% 33% 33%;
      grid-template-rows: 33% 33% 33%;
}

.mobileWrapper {
  display: none;
  text-align: center;
  width: 100vw;
  position: bottom;
}

.mobileHeader {
  z-index: 3;
  position: relative;
  padding-top: 50px;
  color: #e6e6e6;
}

.mobileFrontEnd {
  align-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  -ms-grid-columns: 33% 33% 33%;
      grid-template-columns: 33% 33% 33%;
  justify-items: center;
  z-index: 5;
  position: relative;
}

.mobileBackEnd {
  align-items: center;
  width: 100vw;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  -ms-grid-columns: 33% 33% 33%;
      grid-template-columns: 33% 33% 33%;
  justify-items: center;
  z-index: 5;
  position: relative;
}

.mobileDB {
  align-items: center;
  width: 100vw;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  -ms-grid-columns: 33% 33% 33%;
      grid-template-columns: 33% 33% 33%;
  justify-items: center;
  z-index: 5;
  position: relative;
  padding-bottom: 50px;
}

@media screen and (max-width: 700px) {
  .mobileBackground {
    position: relative;
    width: 100%;
  }
  .mobileBackground::before {
    content: "";
    position: absolute;
    background: url(/static/media/borah.6f16f63e.jpg);
    background-size: cover;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .skillsImg {
    margin-top: 20px;
  }
  .firebaseImg {
    margin-left: 11%;
  }
  .apiImg {
    margin-bottom: 40%;
  }
  .jsonImg {
    margin-bottom: 40%;
  }
  .backgroundOverlay {
    display: none;
  }
  .sceneClass {
    display: none;
  }
  .databaseWrapper, .frontEndWrapper, .backEndWrapper {
    display: none;
  }
  .mobileWrapper {
    display: inline;
    display: initial;
  }
}
/*# sourceMappingURL=skills.css.map */
